export const ARTICLE_PER_PAGE = 9

export const Tabs = [
  { label: 'People', url: 'pursuit-of-passion/people' },
  { label: 'Places', url: 'pursuit-of-passion/places' },
  {
    label: 'Practical Living',
    items: [
      {
        label: 'Home & Design',
        url: 'pursuit-of-passion/practical-living/home-and-design',
      },
      {
        label: 'Wellness',
        url: 'pursuit-of-passion/practical-living/wellness',
      },
      {
        label: 'Travel & Leisure',
        url: 'pursuit-of-passion/practical-living/travel-and-leisure',
      },
      {
        label: 'Finance',
        url: 'pursuit-of-passion/practical-living/finance',
      },
    ],
  },
  { label: 'Videos', url: 'pursuit-of-passion/videos' },
]

export const pursuitPassionPages = [
  { path: 'people/', category: 'people' },
  { path: 'places/', category: 'places' },
  { path: 'videos/', category: 'videos' },
  { path: 'practical-living/home-and-design/', category: 'home & design' },
  { path: 'practical-living/wellness/', category: 'wellness' },
  {
    path: 'practical-living/travel-and-leisure/',
    category: 'travel & leisure',
  },
  { path: 'practical-living/finance/', category: 'finance' },
]
