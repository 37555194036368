import React, { ReactElement, useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'

import Layout from '../../layouts/Layout/Layout'
import PursuitOfPassionLayout from '../../layouts/PursuitofPassionLayout/PursuitOfPassionLayout'
import Seo from '../../components/Seo/Seo'

import bodyData from '../../data/pursuit-passion-body.json'
import {
  addFormValidation,
  setIsIframe,
} from '../../components/Validators/InputValidations'
import loadable from '@loadable/component'

import {
  ARTICLE_PER_PAGE,
  Tabs,
  pursuitPassionPages,
} from './PursuitOfPassionList.utils'
import {
  PursuitOfPassionTypes,
  MainPropTypes,
} from './PursuitOfPassionList.types'
import './PursuitOfPassionList.scss'

const SearchInput = loadable(
  () => import('../../components/SearchInput/SearchInput'),
)

const FeaturedCardSlider = loadable(
  () => import('../../components/FeaturedCardSlider/FeaturedCardSlider'),
)

const Pagination = loadable(
  () => import('../../components/Pagination/Pagination'),
)

const PopupModal = loadable(
  () => import('../../components/PopupModal/PopupModal'),
)

const SideFormModal = loadable(
  () => import('../../components/SideFormModal/SideFormModal'),
)

const NoResult = loadable(() => import('../../components/NoResult/NoResult'))

const ArticleCard = loadable(
  () => import('../../components/ArticleCard/ArticleCard'),
)

const ThirdPartyForm = loadable(
  () => import('../../components/ThirdPartyForm/ThirdPartyForm'),
)

const PursuitOfPassion = ({
  data: {
    featuredPursuitOfPassion: { nodes: featuredPursuitOfPassion },
    allPursuitOfPassion: { nodes: allPursuitOfPassion },
  },
  pageContext: { pageTitle },
}: MainPropTypes): ReactElement => {
  const [dataList, setDataList] =
    useState<PursuitOfPassionTypes[]>(allPursuitOfPassion)
  const [pageDataList, setPageDataList] = useState<PursuitOfPassionTypes[]>(
    dataList.slice(0, ARTICLE_PER_PAGE),
  )

  const slug = pursuitPassionPages.find(
    ({ category }) => pageTitle === category,
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [pages, setPages] = useState(
    Math.ceil(dataList.length / ARTICLE_PER_PAGE),
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSets, setCurrentSets] = useState(1)
  const [showSubscribe, setShowSubscribe] = useState(false)
  const [isSuccessShown, setIsSuccessShown] = useState<boolean>(false)

  const handleSubmit = useCallback(() => {
    setIsSuccessShown(true)
  }, [])

  const handleSuccessClose = useCallback(() => {
    setIsSuccessShown(false)
    setShowSubscribe(false)
  }, [])

  const [featuredImage, setFeaturedImage] = useState<string>(
    featuredPursuitOfPassion.length
      ? featuredPursuitOfPassion[0].featuredImage
      : '',
  )

  const handleAddSets = useCallback((): void => {
    const newSetCount = currentSets + 1
    setPageDataList(dataList.slice(0, newSetCount * ARTICLE_PER_PAGE))
    setCurrentSets(newSetCount)
  }, [currentSets, dataList])

  const handlePageClick = useCallback((page: number): void => {
    window.scrollTo({
      top: 300,
    })
    setCurrentPage(page)
  }, [])

  useEffect(() => {
    setPages(Math.ceil(dataList.length / ARTICLE_PER_PAGE))
  }, [dataList])

  useEffect(() => {
    setPageDataList(
      dataList.slice(
        (currentPage - 1) * ARTICLE_PER_PAGE,
        currentPage * ARTICLE_PER_PAGE,
      ),
    )
  }, [currentPage, dataList])

  useEffect(() => {
    setCurrentPage(1)
    setCurrentSets(1)

    setDataList(
      searchTerm
        ? allPursuitOfPassion.filter((data) =>
            data.title.toLowerCase().includes(searchTerm.toLowerCase()),
          )
        : allPursuitOfPassion,
    )
  }, [allPursuitOfPassion, searchTerm])

  const handleFeaturedChange = useCallback((image: string) => {
    setFeaturedImage(image)
  }, [])

  const getBody = useCallback(() => {
    if (pageTitle === '') return bodyData[0]
    if (pageTitle === 'people') return bodyData[1]
    if (pageTitle === 'places') return bodyData[2]
    if (pageTitle === 'videos') return bodyData[3]
    if (
      pageTitle === 'home & design' ||
      pageTitle === 'wellness' ||
      pageTitle === 'travel & leisure' ||
      pageTitle === 'finance'
    )
      return bodyData[4]
    return bodyData[0]
  }, [pageTitle])

  const handleFormReady = useCallback(() => {
    setIsIframe(true)
    addFormValidation('pop-subscription-form', [
      {
        elementId: 'firstname-ba2d226e-9231-4032-9de4-161460267313',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'lastname-ba2d226e-9231-4032-9de4-161460267313',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'city-ba2d226e-9231-4032-9de4-161460267313',
        validationType: 'alphaOnly',
      },
    ])
  }, [])

  return (
    <Layout navButtonVariant="burger">
      <Seo
        title={`Pursuit of Passion${pageTitle ? ` | ${pageTitle}` : ''}`}
        ogMetaData={{
          title: `Pursuit of Passion${pageTitle ? ` | ${pageTitle}` : ''}`,
          description: getBody(),
          image: featuredImage,
        }}
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/pursuit-of-passion/${
            slug?.path ? slug.path : ''
          }`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, pusuit of passion',
        }}
      />
      <div className="pursuit-passion">
        <PursuitOfPassionLayout
          navigationTabs={Tabs}
          header="Live your way. Live with passion."
          title={pageTitle || 'Pursuit of Passion'}
          body={getBody()}
          background={featuredImage}
        >
          <SearchInput
            name="city"
            placeholder="Find exactly what you are looking for"
            setSearchTerm={setSearchTerm}
            className="search-input"
          />
        </PursuitOfPassionLayout>
        <div className="pursuit-passion-content">
          {!!featuredPursuitOfPassion.length && (
            <FeaturedCardSlider
              data={featuredPursuitOfPassion}
              onChange={handleFeaturedChange}
            />
          )}
          <div className="pursuit-passion-content-subscribe">
            <div className="pursuit-passion-content-subscribe-card">
              <div className="pursuit-passion-content-subscribe-texts">
                <p className="pursuit-passion-content-subscribe-card-header">
                  Subscribe to Pursuit of Passion
                </p>
                <h3 className="pursuit-passion-content-subscribe-card-title">
                  Get our favorite blogs, tips, and lifestyle insights
                </h3>
              </div>
              <div>
                <button
                  type="button"
                  onClick={(): void => setShowSubscribe(true)}
                >
                  SUBSCRIBE
                </button>
              </div>
            </div>
          </div>
          {showSubscribe && !isSuccessShown && (
            <SideFormModal onClose={(): void => setShowSubscribe(false)}>
              <div className="pursuit-passion-subscription-form">
                <ThirdPartyForm
                  id="pop-subscription-form"
                  portalId="4090950"
                  formId="ba2d226e-9231-4032-9de4-161460267313"
                  onFormSubmit={handleSubmit}
                  onFormReady={handleFormReady}
                />
              </div>
            </SideFormModal>
          )}
          <PopupModal
            isShowing={isSuccessShown}
            onClose={handleSuccessClose}
            header="Subscribe to our Newsletter"
            body="You have successfully subscribed to Avida’s newsletter"
          />
          <div className="pursuit-passion-content-cards">
            {pageDataList.length ? (
              pageDataList.map((article) => (
                <ArticleCard
                  key={article.id}
                  data={article}
                  url={`/pursuit-of-passion/${article.slug}/`}
                />
              ))
            ) : (
              <NoResult searchTerm={searchTerm} />
            )}
          </div>
        </div>
        <div className="pursuit-passion-pagination">
          <Pagination
            pages={pages}
            currentPage={currentPage}
            onPageClick={handlePageClick}
          />
        </div>
        {currentSets < pages && (
          <div className="pursuit-passion-load-more">
            <button
              type="button"
              className="pursuit-passion-load-more-button"
              onClick={handleAddSets}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </Layout>
  )
}
export default PursuitOfPassion

export const pageQuery = graphql`
  query PursuitOfPassionListQuery($filterTypes: [String]!) {
    featuredPursuitOfPassion: allPursuitOfPassion(
      filter: { category: { in: $filterTypes }, featured: { eq: true } }
    ) {
      nodes {
        ...PursuitOfPassionListPageArticleFields
      }
    }
    allPursuitOfPassion: allPursuitOfPassion(
      filter: { category: { in: $filterTypes } }
    ) {
      nodes {
        ...PursuitOfPassionListPageArticleFields
      }
    }
  }
`
