import React, { ReactElement } from 'react'
import './PursuitOfPassionLayout.scss'
import ContentNavigation, {
  ContentNavigationTypes,
} from '../../components/ContentNavigation/ContentNavigation'

type LandingPageProps = {
  navigationTabs: ContentNavigationTypes[]
  header?: string
  title: string
  body: string
  children?:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
  background?: string
}

const PursuitofPassionLayout = ({
  navigationTabs,
  header,
  title,
  body,
  children,
  background,
}: LandingPageProps): ReactElement => {
  return (
    <div className="pursuit-passion-layout">
      <ContentNavigation navigations={navigationTabs} center />
      <div
        className="pursuit-passion-layout-overlay"
        style={{
          background: `linear-gradient(to bottom, rgba(153, 13, 21, 0.8), rgba(153, 13, 21, 0.8)), url('${background}') no-repeat scroll center center / cover, linear-gradient(to bottom, #990d14d7, #990d14d7)`,
        }}
      >
        {!!header && (
          <h3 className="pursuit-passion-layout-overlay-header">{header}</h3>
        )}
        <h1 className="pursuit-passion-layout-overlay-title">{title}</h1>
        <p className="pursuit-passion-layout-overlay-body">{body} </p>
        <div className="pursuit-passion-layout-overlay-search">{children}</div>
      </div>
    </div>
  )
}

export default PursuitofPassionLayout
